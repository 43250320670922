
import { ref, onMounted, inject } from 'vue';
import { useRouter } from 'vue-router';
import BaseLayout from "@/components/BaseLayout.vue";
import { getAuth, signOut, signInWithEmailAndPassword, sendPasswordResetEmail  } from "firebase/auth";
import { getAppInfo } from "@/firebase/firebase";
import { Emitter, EventType } from 'mitt';

import INFO from '../../package.json';

import {
    IonInput,
    IonItem,
    IonButton
} from "@ionic/vue";

export default{
    components: {
        BaseLayout,
        IonItem,
        IonButton,
        IonInput
    },

    setup() {
        
        const version = ref(INFO.version); 

        const uid = ref("");
        const email = ref("");
        const password = ref("");
        const isPassworWrong = ref(false);
        const wrongVersion = ref(false);
        const router = useRouter();
        //const store = useStore();
        const auth = getAuth();
        const msg = ref("");

        const emitter = inject("emitter") as Emitter<Record<EventType, unknown>>;

        onMounted(() => {
            console.log("signin mouted")
            uid.value = "";
            email.value = "";
            password.value = "",
            isPassworWrong.value = false;
            msg.value = "Para se cadastrar fale conosco. http://enviepix.com \nWhatsApp: (21)99609-3948";
        })

        function signIn() {
            // if (store.state.autoLogin == true) {
            //     email.value="rogerio.cunha@gmail.com";
            //     password.value="xxxxxxxxx";
            // }

            msg.value = 'Aguarde o login...';
            signInWithEmailAndPassword(auth, email.value, password.value ).then((userCredential) => {
                console.log(userCredential);
                getAppInfo().then((resolve) => {
                    console.log(`Infos obtidas do servidor: ${resolve}`);
                    if (resolve && 'versions' in resolve && resolve.versions.includes(version.value)) {
                        wrongVersion.value = false;
                        router.push("/");
                    }
                    else {
                        wrongVersion.value = true;
                        msg.value="Sua versão está desatualizada. Faça um refresh no browser";
                        signOut(auth).then(() => {
                            console.log('BaseLayout: Logout');
                            emitter.emit("logged_out");
                        })
                    }
                })
                
            }).catch((error) => {
                switch (error.code) {
                    case 'auth/invalid-email':
                        msg.value = 'email inválido'
                        break
                    case 'auth/user-not-found':
                        msg.value = 'Nenhuma conta para este email foi encontrada'
                        break
                    case 'auth/wrong-password':
                        msg.value = 'Password Incorreta';
                        isPassworWrong.value = true;
                        break  
                    default:
                        msg.value = 'Email ou password incorretos';
                        isPassworWrong.value = true;
                        break
                }
            });
        }

        function reset() {
            sendPasswordResetEmail(auth, email.value).then( () => {
                msg.value = 'Email enviado para sua caixa postal';
            })
        }

        emitter.on('logged_out', () => {
            if (wrongVersion.value)
                msg.value = "Sua versão está desatualizada. Faça um refresh no browser";
            else
                msg.value = "Para se cadastrar fale conosco. http://enviepix.com \nWhatsApp: (21)99609-3948";
        })

        return {
            version,
            uid,
            email,
            password,
            msg,
            signIn,
            reset,
            isPassworWrong
        }
    }
}
